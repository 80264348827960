<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>

<script>
export default {
  name: 'app',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  created() {
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="less">
.el-select-dropdown {
  z-index: 9999 !important;
}

.logo-text {
  display: none;
}


a[href="http://map.qq.com?ref=jsapi_v3"] {
  display: none;
}

::v-deep.el-picker-panel {
  z-index: 9999;
}


</style>
