import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Moment from 'moment'
// 引入element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 引入公共样式
import './assets/css/index.scss'
import './assets/css/indexasses.less'
import './assets/css/form-components.scss'
import './assets/css/tabel.scss'
import './assets/css/manage-dialog.scss'
import './assets/css/MarginPaddingMixin.scss'
import './assets/css/after.scss'
import Router from 'vue-router'
// 引入萤石云插件
import EZUIKit from 'ezuikit-js'

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'

import scroll from 'vue-seamless-scroll'

import ws from './assets/js/webscoket'

import { Message } from "element-ui";

Vue.use(scroll)
Vue.use(dataV)
// 引入echarts
import echarts from 'echarts'

Vue.prototype.$echarts = echarts
Vue.prototype.$ezuikit = EZUIKit

import VueAMap from 'vue-amap'

VueAMap.initAMapApiLoader({
  key: 'a5da5b19c0c4169eb23c596cd1b92feb',
  plugin: [
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PlaceSearch',
    'AMap.Geolocation',
    'AMap.Geocoder',
  ],
  v: '1.4.14',
  uiVersion: '1.0',
})
Vue.use(VueAMap)

//引入百度地图api
import BaiduMap from 'vue-baidu-map'
// import { BaiduMap, BmScale, BmGeoslocation } from 'vue-baidu-map'

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'Io8Kv6kC8i35DYPbpqO2lVp8B1Ko9Sa5',
})

import { BmlLushu } from 'vue-baidu-map'

Vue.use(BmlLushu)
import axios, { get, post } from './common/axiosHttp.js'


Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.prototype.$axios = axios
Vue.prototype.$ws = ws
Vue.prototype.moment = Moment

// axios.defaults.baseURL = 'http://test.zhsl.xuruidea.com/api/'
// axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers['token'] = window.localStorage.getItem('token');

Vue.use(ElementUI)
const routerPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch((error) => error)
}

import Viewer from 'v-viewer' //点击图片大图预览
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer)
Viewer.setDefaults({
  //默认样式，可以按需求更改
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source',
  },
})

Vue.config.productionTip = false
Vue.prototype.$message = Message
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
