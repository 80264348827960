import {get, post} from "../common/axiosHttp.js";

// 地图站点列表
export const getAllSiteList = (params) => get("/site/getAllSite", params);
export const getRiverwayDataList = (params) => get("/reachWaterYield/selectReachWaterYieldListAll", params);
// 地图雨晴降雨量
export const getFindAll = (params) => get("/rainfallRegime/findAll", params);
// 首页泵站数量、拦河闸数量和测流点数量
export const selectCountList = (params) => get("/site/getCount", params);
// 查询拦河闸、泵站和测流点、河流数量
export const getCountData = (params) => get("/site/getCount", params);
// 查询站点、闸门详情
export const getSiteByIdList = (params) => get("/site/getSiteById", params);

//  查询渠道流量计
export const getDitchFlowMeter = (params) => get('/ditchFlowMeter/getFlowMeterByMeterId', params)

//  查询流量计
export const getFlowMeter = (params) => get('/flowMeter/getFlowMeter', params)

//  泵站
export const getPumpStation = (params) => get('/pumpStation/getPumpStationById', params)

// 首页查询本月非法入侵抓拍
export const MonthList = (params) =>
  get("/videoAlarmRecord/find/month", params);
// 获取首页年度水位统计、流量统计下拉框
export const siteGetSiteAll = (params) => get("/site/getSiteAll", params);
// 泵站下拉
export const GetPumpStations = (params) => get("/pumpStation/getPumpStations", params);
// 测流点下拉
export const FlowMeterData = (params) => get("/flowMeterData/findAll", params);
// 获取首页年度水位统计、流量统计
export const YearsList = (params, radio1) => get(`/site/getWaterBySite?deviceAddress=${params}&type=${radio1}`);
// // 获取月度数据
export const YearMonth = (params, radio1) => get(`/site/getWaterBySite?deviceAddress=${params}&type=${radio1}`);
// 获取首页年度流量统计
export const FlowList = (params, radio2) => get(`/site/getTrafficBySite?deviceAddress=${params}&type=${radio2}`);
// 获取首页年度流量
export const FlowListMonth = (params, radio2) => get(`/site/getTrafficBySite?deviceAddress=${params}&type=${radio2}`);
// 年度闸泵开启统计

export const gateUpRecordList = (params) =>
  get("/gateUpRecord/getSiteCount", params);

// 测流点列表
export const getFlowMeteList = (params) => get("/site/getFlowMeter", params);
// 报警记录列表
export const RecordList = (params) => get("/alarmRecord/index/findAll", params);
// 报警记录 - 立即处理
export const alarmLogImmediate = (params) =>
  post("/alarmRecord/dispose", params);

// 站点新增
export const siteAdd = (params) => post("/site/add", params);

// 站点删除 /site/deleteById
export const sideDelete = (params) => post("/site/deleteById", params);

// 站点编辑 /site/update
export const sideUpdate = (params) => post("/site/update", params);

// 视频设备添加 /video/add
export const videoAdd = (params) => post("/video/add", params);

// 视频设备编辑
export const videoEdit = (params) => post("/video/update", params);

// 闸门新增
export const gateAdd = (params) => post("gate/add", params);

// 闸门编辑
export const gateUpdate = (params) => post("gate/update", params);

// 雨情遥测模块
// 降雨量查询
export const rainFall = (params) =>
  get("/rainfallRegime/getRainfallMonitoring", params);

// 降雨量三个区域
export const getRainfallTitle = (params) => get("/rainfallRegime/getRainfallTitle", params);

// 日报表
export const rainDay = (params) =>
  get("/rainfallRegime/getRainfallDayReport", params);

// 月报表
export const rainMonth = (params) =>
  get("/rainfallRegime/find/month/Report", params);

// 年报表
export const rainYear = (params) =>
  get("/rainfallRegime/find/year/Report", params);

// 导出
export const rainExport = (params) =>
  get("/rainfallRegime/exportRainDay", params);

// 数据修正
export const dateUpdate = (params) =>
  get("/rainfallRegime/exportRainDay", params);

// 设备列表
export const storeList = (params) => get("/storage/getStorages", params);

// 删除设备
export const storeDelete = (params) => post("/storage/delStorage", params);

export const getRainById = (params) =>
  get("/rainfallRegime/getRainById", params);

// 存储设备编辑
export const storageEdit = (params) => post("/storage/updateStorage", params);

// 存储设备新增
export const storageAdd = (params) => post("/storage/addStorage", params);

// 数据修正新增
export const addRainData = (params) =>
  post("/rainfallRegime/addRainData", params);

// 数据修正编辑
export const updRainDate = (params) =>
  post("/rainfallRegime/updRainDate", params);

// New 大数据页面获取闸门统计
export const Turnonstatistics = (params) =>
  get("/gateUpRecord/getSiteHistoryCount", params);

// New 大数据安防报警饼状图
export const SecurityBingLing = (params) =>
  get("/alarmRecord/getSiteHistoryCount", params);


// New 查询大数据水位曲线图
export const Waterleveldata = (params) =>
  get("/site/getBigDataWaterLevelBySite", params);

// New 大数据安防报警分页列表
export const SecurityPage = (params) =>
  get("/alarmRecord/getSiteHistoryPage", params);

// New 大数据流量曲线图
export const Flowinterface = (params) =>
  get("/site/getBigDataTrafficBySite", params);

