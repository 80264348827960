import axios from "axios";
import router from "../router";
import store from "../store/index";
import { Message, Loading, MessageBox } from "element-ui";
var comUrl = "";
switch (process.env.VUE_APP_CURENV) {
  case "development": //开发环境
    console.log('dev')
    // comUrl = "https://test.zhsl.xuruidea.com/api/";
    // comUrl = 'http://192.168.0.106:10001/'  // 胡凯 9501
    // comUrl = "https://test.yxzhsl.xuruidea.com/api"
    comUrl = 'http://101.200.43.114:10003'  // Mr.MarkDown
    break;
  case "test": //测试环境
    console.log('test')
    comUrl = "https://test.yxzhsl.xuruidea.com/api/";
    break;
  case "uat": //生产环境
    console.log('uat')
    comUrl = "https://uat.yxzhsl.xuruidea.com/api/";
    break;
  case "production": //生产环境
    console.log('production')
    // comUrl = "https://yxzhsl.xuruidea.com/api/";
    comUrl = "/api";

    break;
}
axios.defaults.baseURL = comUrl;

/**
 * 提示函数
 * @param {*} msg
 *
 */
const tip = (msg) => {
  Message.closeAll();
  Message({
    showClose: true,
    center: true,
    message: msg || "",
    type: "error",
  });
};
/**
 * 跳转登录页
 *
 */
const toLogin = () => {
  router.replace({
    path: "/",
  });
};

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    // 401: 跳转登录页
    case 401:
      console.log(other);
      toLogin();
      break;
    case 403:
      tip("权限不足，请联系管理员");
      break;
    case 404:
      tip("请求的资源不存在");
      break;
    case 500:
      tip("服务器异常，请稍后重试");
    default:
      console.log(other);
  }
};

// 创建axios实例
var instance = axios.create({
  timeout: 1000 * 60,
  baseURL: comUrl, // 该有的环境地址
});

// 设置post请求头
instance.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
instance.defaults.headers.get["Content-Type"] =
  "application/x-www-form-urlencoded";
  instance.defaults.headers.delete["Content-Type"] =
  "application/x-www-form-urlencoded";

instance.interceptors.request.use(
  (config) => {
    let token = window.localStorage.getItem("token");
    if (token) {
      // 添加headers
      config.headers["token"] = token;
    } else {
    }
    return config;
  },
  (error) => Promise.error(error)
);

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  async (res) => {
    if (res.data.data === null) {
      // 解构默认值使用
      res.data.data = undefined;
    }
    if (res.data.msg === undefined) {
      // 提示默认值
      res.data.msg = "";
    }
    if (res.data.code === 403) {
      sessionStorage.clear();
      localStorage.clear();
      await MessageBox.confirm("用户信息已过期，请重新登录", "提示", {
        showClose: false,
        showCancelButton: false,
        closeOnPressEscape: false,
        closeOnClickModal: false,
        confirmButtonText: "退出",
        type: "warning",
      });
      location.href = "/";
      return Promise.reject(res.data);
    } else if (res.data.code !== 200) {
      return Promise.reject(res.data);
    } else {
      return res.data;
    }
    // return res.status === 200 ? Promise.resolve(res.data) : Promise.reject(res.data)
  },
  // 请求失败
  (error) => {
    // console.log(JSON.parse(JSON.stringify(error)));
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      // errorHandle(response.status, response.message);
      return Promise.reject(response);
    } else {
      // 处理其他的情况
      if (error.message) {
        // 取消请求的message
        tip("网络请求失败");
        // toLogin();
      } else {
        tip("服务故障，请检查！");
        toLogin();
      }
      return Promise.resolve({}); // 解决业务解构报错
    }
  }
);

// 封装get请求
export function get(url, params) {
  return new Promise(function (resolve, reject) {
    instance
      .get(url, {
        params,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
// 封装post请求
export function post(url, params) {
  return new Promise(function (resolve, reject) {
    instance
      .post(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
// 封装DELETE请求
// export function delete(url, params) {
//   return new Promise(function (resolve, reject) {
//     instance
//       .delete(url, params)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// }
export default instance;
